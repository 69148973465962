#react-paginate ul {
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
}

#react-paginate li {
    display: inline-block;
}

.pagination {
    margin: 0px;
}

.table-footer {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 20px 30px 10px 30px;
}

.previous-button {
    cursor: pointer;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    color: #636365;
    font-family: Montserrat;
    border: 1px solid #636365;
    border-radius: 10px;
    margin-right: 15px;
}

.next-button {
    cursor: pointer;
    margin-left: 15px;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 600;
    color: #636365;
    font-family: Montserrat;
    border: 1px solid #636365;
    border-radius: 10px;
}
.page-link {
    cursor: pointer;
    padding: 5px;
    border-radius: 13px;
    width: 25px;
    display: flex;
    border-width: 0px;
    justify-content: center;
    align-items: center;
    /* margin: 0px 5px; */
    font-size: 12px;
    border: 1px solid #636365;
    border-color: #636365;
    color: #636365 !important;
    margin-right: 5px;
    margin-left: 5px;
}

.active-page-link {
    cursor: pointer;
    padding: 5px;
    border-radius: 13px;
    width: 5;
    color: white !important;
    background-color: #252422;
    border-color: #252422;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.active-page-link:hover {
    cursor: pointer;
    color: white !important;
}

.active-link {
    color: white;
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    align-items: center;
}
