.account-management-page {
    flex: 1;
    background-color: white;
    width: 100%;
}

.account-loader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.account-detail-container {
    max-width: 800px;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    display: flex;
    flex-direction: column;
    padding: 60px;
    margin: 50px auto;
}
