.charts-row {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 3px 6px #00000029;
}

.bar-chart-card {
    width: 100%;
    max-width: 48%;
}

.bar-chart-card .card-body {
    display: flex;
    align-items: center;
    justify-content: center;
}

.bar-chart-card .chart-wrapper {
    width: 100%;
}

@media (max-width: 575px) {
    .bar-chart-card {
        max-width: 100%;
    }
}

@media (max-width: 400px) {
    .card-style {
        padding: 15px 10px;
        border-radius: 10px;
    }
}

.card-style .card-body {
    min-height: 150px;
    position: relative;
    padding: 15px 0 0;
}

.card-style {
    box-shadow: none;
}

.card-head {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 10px;
}

.card-head h5 {
    color: #252422;
    font-weight: bold;
    font-size: clamp(13px, 2vw, 16px);
    width: 100%;
    max-width: 150px;
    margin-bottom: 0;
}

.loader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.chart-wrapper {
    width: 100%;
    max-width: 100%;
    margin: 25px auto 40px;
}

@media (max-width: 1300px) {
    .chart-wrapper {
        width: 100%;
        max-width: 270px;
        margin: 10px auto 25px;
    }
}

@media (max-width: 575px) {
    .chart-wrapper {
        max-width: 200px;
        margin: 10px auto 25px;
    }
}

.form-select {
    border: 1px solid #70707087;
    border-radius: 9px;
    padding: 5px;
    font-weight: 700;
}
