.create-payment-link-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.cursor-css {
    cursor: pointer;
}

.light-opacity {
    opacity: 0.7;
}

.dark-opacity {
    opacity: 1;
}

.commission-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    padding-top: 60px;
    padding-bottom: 60px;
}

.generate-link-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    padding-top: 60px;
    padding-bottom: 60px;
}
