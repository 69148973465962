.tabSetting {
    display: contents;
}

.react-tabs__tab-list {
    border-bottom: none;
}
.setting_img {
    height: 90px;
    width: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: white;
    color: #04acdd;
    border: 1px solid #04acdd;
    overflow: hidden;
    margin-bottom: 20px;
}
.setting_img h3 {
    font-weight: bold;
    padding-top: 10px;
}
.setting_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.setting-loader {
    flex: 1;
    position: relative;
    margin-top: 230px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.cam_button {
    margin-left: -10px;
    margin-top: -10px;
    margin-bottom: 0;
    padding: 0 !important;
}

.cam_button .MuiButtonBase-root {
    background-color: #5ec9e9 !important;
    padding: 5px;
}

.cam_button .MuiButtonBase-root svg {
    color: #fff !important;
    font-size: 18px;
}

.setting-tabs-box {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    column-gap: 20px;
}

.setting-tabs-box .react-tabs__tab-list {
    width: 100%;
    max-width: 250px;
    padding-top: 10px;
}

.setting-tabs-box .react-tabs__tab-panel {
    width: 100%;
    max-width: calc(100% - 270px);
}

.tab-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 15px;
    margin-top: 30px;
}

.tabSetting .inner-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.tabSetting .inner-wrapper .big-text {
    padding-left: 0;
    font-size: clamp(15px, 2vw, 18px);
    margin-bottom: 0;
    font-family: Montserrat;
}

.tab-heading .inner-wrapper .small-text {
    color: grey;
    font-weight: normal;
    padding-left: 0;
    font-size: clamp(12px, 2vw, 14px);
}

.input-fields-row {
    display: inline-block;
    border-left: 1px solid #eaecf0;
    padding-top: 15px !important;
    padding-right: 0;
    max-width: 72%;
    padding-left: 0;
}

.input-fields-row .text-data {
    padding: 0 26px;
    margin-top: 30px;
}

.input-fields-row .text-data h5 {
    margin-top: 30px;
    font-weight: bold;
    font-size: clamp(15px, 2vw, 18px);
}

.input-fields-row .text-data p {
    font-size: clamp(12px, 2vw, 14px);
    color: grey;
    width: 100%;
    max-width: 80%;
}

.password-fields-box {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 100px;
    padding: 0 26px;
}

.password-fields-box .custom-row {
    margin: 20px auto 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
    row-gap: 5px;
}

.password-fields-box .custom-row > label {
    width: 100%;
    max-width: 180px;
    font-size: clamp(12px, 2vw, 14px);
    margin-bottom: 0;
}

.password-fields-box .custom-row .field-box {
    width: 100%;
    max-width: 250px;
    min-width: 230px;
    font-size: clamp(12px, 2vw, 14px);
}

.button-wrapper-box {
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 20px;
}

.button-wrapper-box .common-btn {
    background-color: #04acdd !important;
    width: 100%;
    max-width: 130px;
    color: white;
    font-size: 13px;
    text-transform: capitalize;
    font-weight: light;
    border-radius: 10px;
    height: unset;
    padding: 8px 5px;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.3);
}

.button-wrapper-box .common-btn:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.3);
}

.button-wrapper-box .outline-btn {
    border-color: #04acdd;
    color: #04acdd;
    background-color: #fff !important;
    box-shadow: none !important;
}

.button-wrapper-box .outline-btn:hover {
    background-color: #f5f5f5 !important;
}

@media (max-width: 1300px) {
    .input-fields-row {
        max-width: 100% !important;
    }
}

@media (max-width: 820px) {
    .setting-tabs-box .react-tabs__tab-list {
        max-width: 200px;
    }
    .setting-tabs-box .react-tabs__tab-panel {
        width: 100%;
        max-width: calc(100% - 220px);
    }
}

@media (max-width: 767px) {
    .input-fields-row .image-wrapper {
        margin-top: 15px !important;
    }
    .input-fields-row .img-box {
        padding-left: 10px !important;
    }
    .input-fields-row .title-heading {
        padding-left: 0 !important;
    }
    .input-fields-row {
        margin-top: 20px;
        max-width: 100% !important;
        padding-left: 20px;
    }
    .input-fields-row.password-wrapper {
        padding-left: 0 !important;
    }
    .input-fields-row .col-md-5,
    .input-fields-row .col-md-10,
    .input-fields-row .col-md-12 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .password-fields-box .custom-row {
        flex-direction: column;
        align-items: flex-start;
    }
    .password-fields-box .custom-row .field-box {
        max-width: 100%;
    }
    .input-fields-row .text-data {
        margin-top: 0;
    }
    .input-fields-row .text-data h5 {
        margin-top: 15px;
    }
    .input-fields-row .text-data p {
        max-width: 100%;
    }
}

@media (max-width: 575px) {
    .setting-tabs-box .react-tabs__tab-list,
    .setting-tabs-box .react-tabs__tab-panel {
        max-width: 100%;
    }
    .setting-tabs-box .react-tabs__tab-list div {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        column-gap: 8px;
    }
    .tab-heading {
        margin-top: 10px;
        flex-direction: column;
        align-items: center !important;
        row-gap: 5px;
        justify-content: center;
        width: 100%;
        max-width: calc(100% / 2 - 4px);
    }
    .tab-heading .inner-wrapper {
        row-gap: 0;
        text-align: center;
        width: 100%;
        align-items: center;
    }
    .tab-heading .inner-wrapper span {
        display: block !important;
        min-width: 100%;
    }
    .tab-heading svg {
        transform: rotate(90deg);
    }
    .input-fields-row {
        border-left: 0 !important;
        border-top: 1px solid #eaecf0 !important;
        padding-left: 0;
    }
    .password-fields-box {
        margin-top: 20px;
    }
    .input-fields-row .text-data,
    .password-fields-box {
        padding: 0;
    }
}

@media (max-width: 450px) {
    .password-fields-box {
        margin-bottom: 50px;
    }
    .button-wrapper-box {
        justify-content: center;
        padding-right: 0;
    }
    .button-wrapper-box .common-btn {
        border-radius: 8px;
        padding: 10px 3px;
        font-size: 12px;
        max-width: 120px;
    }
}

.setting-tabs-box .MuiInputBase-root legend {
    width: unset !important;
}

.setting-tabs-box .MuiFormLabel-root {
    background: #fff !important;
    padding-left: 5px;
    padding-right: 5px;
}

.MuiTabs-flexContainer {
    justify-content: center;
}

.MuiTab-textColorSecondary.Mui-selected {
    color: white !important;
    background-color: #252422 !important;
}

.MuiTab-textColorSecondary.Mui-selected:focus {
    border-color: #252422 !important;
}

.MuiTabs-indicator {
    height: 0px !important;
}
