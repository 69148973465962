.create-qr-code-page {
    flex: 1;
    background-color: white;
    width: 100%;
}

.mask-box {
    border-radius: 10px;
    position: relative;
    background: #252422;
    box-shadow: 1px 1px 2px rgb(165, 165, 165) inset;
}

.mask-box .mask {
    width: 150px;
    height: 40px;
    border-radius: 10px;
    background: #fbfbff;
    position: absolute;
    box-shadow: 1px 0 2px #9999a9;
    transition: all 0.5s ease;
}

.mask-box .MuiButton-root {
    border-radius: 10px;
    width: 150px;
    height: 40px;
    font-weight: 600;
    transition: all 0.2s 0.1s ease;
    text-transform: capitalize;
    font-size: 12px;
}

.mask-box .MuiButton-root:hover {
    opacity: 0.8;
}

.qrcode-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.qrcode-outer-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    padding: 30px 120px 60px 120px;
}

.qrcode-inner-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qrcode-custom-outer-container {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 33px;
    padding: 30px 120px 60px 120px;
    display: flex;
    flex-direction: column;
}

.qr-code-content-container {
    padding: 20px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 21px;
}
