.background-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1; /* Ensure the video is behind the content */
    opacity: 1;
}
