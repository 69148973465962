.external-payment-container {
    padding: 80px;
}

.prefilled-content {
    padding: 5px 15px;
    cursor: not-allowed;
    color: #707070;
}

.storm-icon {
    width: 87px;
}

.done-icon {
    width: 150px;
}

@media screen and (max-width: 426px) {
    .external-payment-container {
        padding: 10px;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .storm-icon {
        width: 47px;
    }

    .done-icon {
        width: 80px;
    }
}

.img-and-btn-container {
    display: flex;
    flex-direction: column;
}

.paynest-collect-logo {
    margin-top: 70px;
    margin-bottom: 90px;
}

@media screen and (max-width: 428px) {
    .paynest-collect-logo {
        display: none;
    }

    .img-and-btn-container {
        flex-direction: column-reverse;
    }
}

.page-loader {
    margin-top: 50px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    row-gap: 20px;
}
