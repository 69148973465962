.user-management-page {
    flex: 1;
    background-color: white;
    width: 100%;
}

.user-loader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.student-popup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 10px;
}

.student-popup .left-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
}

.student-popup .right-column > div {
    column-gap: 10px;
    display: flex;
}

.dropdown-container {
    border-radius: 5px;
    border: 1px solid gray;
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    color: black;
    font-weight: 400;
    font-size: 14px;
    padding: 0px 8px;
}

input[type='date']::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url(https://img.icons8.com/material-sharp/24/4a90e2/date-to.png)
        no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
}

@media (max-width: 730px) {
    .student-popup {
        flex-wrap: wrap;
        row-gap: 10px;
    }

    .student-popup > div {
        width: 100%;
        max-width: 100%;
    }

    .student-popup .left-column {
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 10px;
    }

    .student-popup .left-column > * {
        max-width: 100%;
    }
}

.update-bulk-student {
    cursor: pointer;
    border-radius: 15px;
    text-transform: 'none';
    font-family: Montserrat;
    font-size: 0.8vw;
    font-weight: 700;
    border-color: #006e7d;
    color: #006e7d;
    background-color: white;
    height: 40px;
    width: 10vw;
    border: 1px solid #006e7d;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.update-bulk-student:hover {
    cursor: pointer;
    border-color: white;
    color: white;
    background-color: #006e7d;
    border: 1px solid #006e7d;
}

.MuiBackdrop-root {
    backdrop-filter: blur(3px);
    background-color: rgba(0 0 0 / 10%);
}

.MuiDialog-container.MuiDialog-scrollPaper {
    width: 100%;
    /* max-width: 900px; */
    max-width: 50vw;
}

.MuiDialog-paperScrollPaper {
    margin: 0 !important;
    width: 100%;
    max-width: 100% !important;
    border-radius: 15px;
    overflow: hidden;
}

.MuiDialog-paperScrollPaper > div {
    width: 100%;
    /* max-width: 800px; */
}

.MuiDialog-paperScrollPaper > div > div:nth-child(2) {
    column-gap: 16px;
    row-gap: 16px;
    /* flex-wrap: wrap; */
}

.MuiPaper-rounded {
    border-radius: 12px;
}

.dialog-component {
    box-shadow: 1px 1px 10px #00000029;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    max-width: calc(70% / 2 - 8px);
    margin-right: 10px !important;
    margin-left: 10px !important;
    margin-bottom: 30px !important;
}

.dialog-component:hover {
    box-shadow: 1px 1px 10px #888888;
}

.pre-dialog-note {
    padding: 20px;
    display: flex;
    font-weight: 400;
    align-items: center;
    justify-content: center;
}

.add-student-form {
    padding: 0px 0px 20px 0px;
    font-weight: 700;
    font-size: 15px;
    color: rgb(13, 110, 124);
}

/* Notify Parent Dialog  */

.notify_dialog_description {
    margin-bottom: 20px !important;
    font-weight: 500 !important;
}

.notify_dialog_heading {
    margin-bottom: 20px !important;
}

.notify_dialog_buttons {
    display: flex;
    justify-content: center;
    margin-top: 1em;
}

.notify_dialog_buttons {
    margin-left: 1em !important;
    display: inline-block;
    display: flex;
    column-gap: 15px;
}

.notify_dialog_buttons button {
    padding: 12px !important;
    font-size: clamp(12px, 2vw, 14px) !important;
    width: 100%;
    max-width: 150px !important;
}

/* success dialog  */

.success_card {
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.success_circle {
    border-radius: 120px;
    height: 120px;
    width: 120px;
    background: #d9e9ec;
    margin: 0 auto;
}

.success_checkmark {
    color: #006e7d;
    font-size: 60px;
    line-height: 120px;
    margin-left: 0.5em;
}

.success_success {
    color: #2b2c2b;
    font-family: Montserrat;
    font-weight: 900;
    font-size: 14px;
    margin-bottom: 10px;
    padding: 2.5em 0em;
}

.success_message {
    width: 180px;
    margin: 0 auto;
    text-align: center;
    color: #006e7d;
}

.link {
    /* royalblue */
    color: rgb(30, 173, 219);
    font-weight: 500;
    text-align: center;
    cursor: pointer;
}

/* Confirmation Student Delete Dialog */

.confirmation_card {
    max-width: 480px;
    margin: auto;
    padding: 25px;
}

.confirmation_card h5 {
    font-size: 17px;
    font-weight: bold;
    margin: 2px;
}

.confirmation_card p {
    font-size: 15px;
    font-weight: bold;
    margin: 18px 2px;
}

.confirmation_buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.confirmation_buttons .btn1 {
    margin-right: 20px;
    margin-top: 10px;
}

.invoice-form-header .inner-wrapper .img-box {
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 15px;
}

.invoice-form-header .inner-wrapper .img-box > div:first-child {
    height: 70px;
    width: 70px;
    background-color: rgb(0, 110, 125);
}

.invoice-form-header p {
    margin: 0;
    font-size: 12px;
    font-weight: 500;
    text-transform: capitalize;
    color: gray;
}

.invoice-form-header .column-1,
.invoice-form-header .column-2 {
    padding: 0px !important;
}

.invoice-form-header .column-2 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 10px;
}

.invoice-form-header .column-2 .date-text {
    text-align: right;
}

.invoice-form-header .column-2 .fees-text {
    font-size: 12px;
    align-items: center;
    padding-left: 10px;
}

.invoice-form-header .column-2 .fees-text strong {
    color: #006e7d;
}

.invoice-table-header > div {
    max-width: 100%;
    width: 100%;
}

.invoice-table-header .left-column {
    row-gap: 10px;
}

.invoice-table-header .left-column .student_SearchBar {
    min-width: 100px !important;
    width: 100%;
    max-width: 300px !important;
}

.user-management-container {
    margin-bottom: 50px;
}

.user-management-container .student_SearchBar {
    width: 100%;
    min-width: 300px !important;
}

.invoice-table-header .right-column > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
}

@media (max-width: 1100px) {
    .invoice-table-header {
        flex-wrap: wrap;
        row-gap: 10px;
    }
}

@media (max-width: 575px) {
    .dialog-component {
        max-width: 100%;
    }

    .notify_dialog_buttons {
        width: 100%;
        justify-content: center;
    }

    .notify_dialog_buttons button {
        padding: 7px 12px !important;
        max-width: 100px !important;
        min-width: 100px !important;
    }
}

@media (max-width: 450px) {
    .invoice-form-header .inner-wrapper .img-box {
        column-gap: 7px;
    }

    .invoice-form-header .inner-wrapper .img-box > div:first-child {
        height: 40px;
        width: 40px;
    }

    .invoice-form-header .column-2 {
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
    }

    .invoice-form-header .column-2 .date-text {
        margin-bottom: 7px;
    }

    .invoice-form-header .column-2 .fees-text {
        border: none;
    }

    .invoice-table-header .left-column .student_SearchBar,
    .user-management-container .student_SearchBar {
        width: 100%;
        min-width: 200px !important;
    }
}

.custom-label-wrapper > div label {
    position: absolute !important;
    top: -7px !important;
    font-size: 12px !important;
    background: #fff !important;
    left: 12px !important;
    padding: 0 5px !important;
    transform: unset !important;
}

.notifyBtnCssSt > * {
    margin-right: 5px !important;
    margin-left: 5px !important;
}

.progress-bar > div {
    background-color: #bbd84c !important;
}

.notification_card {
    padding: 20px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin: 0 auto;
}

.table-header {
    margin-bottom: 10px;
}

.custom-phone-input > .special-label {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 11px !important;
    color: black;
}

.custom-phone-input:visited > .special-label {
    color: #5ec9e9 !important;
}
