@import '@fontsource/montserrat';

.home-table-header {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}

.home-table-card {
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: 'Montserrat';
}

table {
    border-collapse: separate !important;
    overflow: hidden;
    border-radius: 15px;
}

table thead {
    background: #36404f;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 23px;
    height: 74px;
}

table th:nth-child(1) {
    border-radius: 30px 0px 0px 30px;
}
table th:nth-last-child(1) {
    border-radius: 0px 30px 30px 0px;
}

.table-avatar {
    height: 30px !important;
    width: 30px !important;
    margin-right: 1.8rem !important;
    padding: 10px !important;
    font-size: 0.85rem !important;
    font-weight: bold !important;
    color: black !important;
    font-family: Montserrat !important;
    font-weight: bold !important;
    background: transparent linear-gradient(180deg, #1ae4d0 0%, #eeeb9a 100%) 0%
        0% no-repeat padding-box !important;
}

#notistack-snackbar {
    font-family: Montserrat !important;
}

@media screen and (max-width: 426px) {
    .mobile-responsive-portal {
        font-size: 14px !important;
    }
}
