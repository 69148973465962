.widget-container {
    width: 100%;
    max-width: calc(89% / 3);
    border-radius: 25px;
    min-height: 110px;
    display: flex;
    padding: 10px 15px;
    background-color: #252422;
    text-decoration: none;
    box-shadow: 0px 3px 6px #00000029;
}

.widget-inner-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    text-align: start;
}

.widget-name {
    color: #8c8c8c;
    font-size: small;
    font-weight: 500;
}

.widget-value-container {
    display: flex;
    flex-direction: column;
    position: relative;
}

.widget-value {
    color: black;
    font-weight: bold;
    font-size: 25px;
    margin-bottom: 0;
}

.widget-top-text {
    font-size: 13px;
    font-weight: bold;
}

@media (max-width: 815px) {
    .widget-container {
        max-width: calc(89% / 3);
    }
}

@media (max-width: 575px) {
    .widget-container {
        max-width: calc(97% / 2);
    }
}

@media (max-width: 450px) {
    .widget-container {
        max-width: 100%;
        min-height: 95px;
    }

    .widget-top-text {
        bottom: 0;
    }
}

.widget-list-container {
    margin-top: 15px;
    border-radius: 16px;
}

.widget-list-container {
    margin-top: 15px;
    border-radius: 16px;
}

.widget-list-title {
    width: 100%;
    color: #252422;
    font-weight: bold;
    font-size: 16px;
}

.widget-list {
    margin-top: 10px;
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    column-gap: 1.5%;
    row-gap: 10px;
    flex-wrap: wrap;
}

@media (max-width: 767px) {
    .widget-list {
        column-gap: 2%;
    }
}

@media (max-width: 575px) {
    .widget-list {
        column-gap: 3%;
    }
}
