.transactions-management-page {
    flex: 1;
    background-color: white;
    width: 100%;
}

.transactions-loader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
