.sidebar-text {
    cursor: pointer;
    display: flex;
    align-items: center;
    color: black;
    padding: 0.3vw 1.5vw 0.3vw 0.5vw;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    width: 100%;
    font-family: Montserrat;
}

.sidebar-text-selected {
    font-family: Montserrat;
    display: flex;
    cursor: pointer;
    color: white;
    border-top-left-radius: 2px;
    margin-left: 20px;
    margin-bottom: 10px;
    padding: 0.5vw 1.5vw 0.5vw 0.5vw;
    align-items: center;
    margin-right: 20px;
    width: auto;
    font-weight: bold;
    background-color: #334050;
    border-radius: 10px;
}

.sidebar-text-selected:hover {
    color: white;
}

.sidebar-text-selected:focus {
    color: white;
}

.sidebar-text-selected:visited {
    color: white;
}

.dashboard_icon {
    background-image: url(../../assets/navbar/home-navbar-icon.svg);
}

.sidebar-text-selected .dashboard_icon {
    background-image: url(../../assets/navbar/home-hover-navbar-icon.svg);
}

.user_icon {
    background-image: url(../../assets/navbar/user-navbar-icon.svg);
}

.sidebar-text-selected .user_icon {
    background-image: url(../../assets/navbar/user-hover-navbar-icon.svg);
}

.transaction_icon {
    background-image: url(../../assets/navbar/transaction-navbar-icon.svg);
}

.sidebar-text-selected .transaction_icon {
    background-image: url(../../assets/navbar/transaction-hover-navbar-icon.svg);
}

.account_icon {
    background-image: url(../../assets/navbar/account-navbar-icon.svg);
}

.sidebar-text-selected .account_icon {
    background-image: url(../../assets/navbar/account-hover-navbar-icon.svg);
}

.report_icon {
    background-image: url(../../assets/navbar/reports-navbar-icon.svg);
}

.sidebar-text-selected .report_icon {
    background-image: url(../../assets/navbar/reports-hover-navbar-icon.svg);
}

.support_icon {
    background-image: url(../../assets/navbar/support-navbar-icon.svg);
}

.sidebar-text-selected .support_icon {
    background-image: url(../../assets/navbar/support-hover-navbar-icon.svg);
}

.setting_icon {
    background-image: url(../../assets/navbar/setting-navbar-icon.svg);
}

.sidebar-text-selected .setting_icon {
    background-image: url(../../assets/navbar/setting-hover-navbar-icon.svg);
}

.menu_options_icon {
    background-size: 100% 100%;
    width: 18px;
    height: 18px;
    margin-right: 15px;
    background-repeat: no-repeat;
    background-position: center;
}

.menu_options .MuiSvgIcon-root {
    margin-right: 15px;
}

.sidebar-container {
    width: 100%;
    max-width: 16.9vw;
    min-width: 16.9vw;
    height: 100%;
    transition: all 0.5s ease-in-out;
    border-right: 1px solid #d8d8d8;
}

@media screen and (max-width: 426px) {
    .sidebar-container {
        max-width: 50.9vw !important;
    }
}

@media (max-width: 1024px) {
    .sidebar-container {
        position: fixed;
        bottom: 0;
        left: 0;
        height: calc(100vh - 62px) !important;
        z-index: 11111;
        margin-left: -230px;
    }

    .sidebar-container .brand_img {
        display: none;
    }

    .sidebar-container.show {
        margin: 30px 20px 0px 0px;
        transition: all 0.5s ease-in-out;
    }

    .sidebar-container.hide {
        display: none;
        transition: all 0.5s ease-in-out;
    }

    .menu_options {
        margin-top: 10px;
        margin-left: 0;
        font-size: 13px;
    }

    .topbar-main-container .headerUpper {
        display: flex !important;
    }
}

.dashboard-main-container {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    width: 100%;
    max-width: calc(100% - 16.9vw);
}

@media (max-width: 1024px) {
    .main-content {
        min-height: calc(100vh - 132px);
        max-height: calc(100vh - 132px);
    }

    .dashboard-main-container {
        max-width: 100%;
    }
}

.main-content {
    height: 100%;
    min-height: calc(100vh - 120px);
    max-height: calc(100vh - 120px);
    overflow-y: scroll;
    padding: 20px;
    background-color: white;
}

.main-content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.main-content::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.main-content::-webkit-scrollbar-thumb {
    background-color: #252422;
}

.centered-data {
    width: 100%;
    max-width: 98%;
    margin: 0 auto;
}

.topbar-search-container {
    width: 100%;
    max-width: 300px;
    padding: 7px 10px;
}

.topbar-main-container .headerUpper img {
    width: 85px;
}

.topbar-main-container .headerUpper {
    background-color: #252422;
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 20px;
    display: none;
}

.topbar-main-container .headerUpper img {
    width: 10.2vw;
    transition: all 0.5s ease-in-out;
}

.topbar-main-container .headerInner {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    max-width: 95%;
    padding: 10px 0;
    margin: 0 auto;
}

.sidebar-trigger button {
    background-color: #fff !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 4px;
    padding: 7px 10px;
    width: 40px;
    height: 30px;
    border-radius: 4px !important;
}

.sidebar-trigger button span {
    display: inline-block;
    height: 1px;
    background-color: #252422;
    width: 100%;
}

.topbar-searchbox {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    border: 1px solid #006b7a;
    margin-top: 15px;
}

.topbar-searchbox:active {
    border-radius: 10px;
    border: 1px solid #006b7a;
}

.topbar-search-input {
    margin-left: 10px;
    border: 0px;
    width: 85%;
    font-size: 10px;
    padding: 10px;
    padding-left: 9px;
    background-color: transparent;
    color: black;
    font-family: Montserrat;
}

.search_grid_container {
    position: absolute;
    top: 70px;
    left: 40px;
    min-height: 200px;
    max-height: 200px;
    width: 100%;
    max-width: 350px;
    box-shadow: 0 0 10px rgba(0 0 0 / 10%);
    overflow-y: auto;
    background: whitesmoke;
    border-radius: 10px;
    padding: 10px 15px;
    z-index: 10;
}

.notificationUserDialog {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.notificationUserDialog .inner-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;
}

.notificationUserDialog .bell-icon {
    border-radius: 50px;
    height: 35px;
    width: 35px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer !important;
}

.bell-button {
    background-color: transparent;
    border-style: none;
}

.bell-button:focus {
    outline: none !important;
    background-color: transparent;
}

.user-profile-btn {
    padding: 0px !important;
    text-align: right;
    background-color: transparent !important;
    border-radius: 0px !important;
    box-shadow: none !important;
    color: #000 !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 7px;
}

.btn-primary:not([disabled]):not(.disabled):active {
    background-color: transparent !important;
}

.user-profile-btn h6 {
    font-family: Montserrat;
    text-transform: capitalize;
    margin-bottom: 0;
    font-size: clamp(13px, 2vw, 16px);
    line-height: 1;
}

.user-profile-btn span {
    font-size: 13px;
    text-transform: capitalize;
    color: #888888;
    font-weight: 500;
    font-size: clamp(11px, 2vw, 14px);
}

.user-profile-btn i {
    display: none;
}

.notify_pop {
    position: absolute;
    z-index: 102;
    right: 50px;
    top: 80px;
    box-shadow: 0 0 3px #888888;
    min-height: 300px;
    max-width: 300px;
    min-width: 300px;
    border-radius: 20px;
    background: white;
}

.notify_grid::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.notify_grid::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.notify_grid::-webkit-scrollbar-thumb {
    background-color: #252422;
}

.notify_grid {
    min-height: 100px;
    max-height: 220px;
    overflow-y: auto;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.close_PopUp {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    /* background: rgba(0, 0, 0, 0.1); */
    z-index: -1;
}

.dashboard-page {
    display: flex;
    min-height: 100vh;
    width: 100%;
}

.sidebar {
    background-color: white;
    overflow: hidden;
    height: 100%;
    min-height: 100vh;
    padding: 0;
    position: relative;
    /* border: 1px solid lightgray; */
    flex-wrap: wrap;
    /* height: 100%; */
    /* min-height: calc(100vh - 70px);
  max-height: calc(100vh - 70px); */
}

.sidebar::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.sidebar::-webkit-scrollbar-track {
    background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #252422;
}

.brand_img {
    text-align: left;
    border-bottom: 1px solid lightgrey;
    margin-top: 40px;
    margin-bottom: 10px;
    margin-right: 10px;
    margin-left: 20px;
    padding: 10px 10px 30px 10px;
    display: flex;
    align-items: center;
}

.brand_img img {
    width: 100%;
    /* max-width: 125px; */
    max-width: 5vw;
}

.sidebar-inner-container {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: flex-start;
}

.menu_options {
    max-width: 100%;
    margin-top: 15%;
}

.logout-container {
    display: flex;
    align-items: center;
    color: rgb(198, 19, 19);
    padding: 15px 0 15px 10px;
    margin-left: 40px;
    margin-right: 20px;
    margin-bottom: 20px;
    width: fit-content;
    cursor: pointer;
    font-family: Montserrat;
}

.logout_icon {
    background-image: url(../../assets/navbar/logout-icon.png);
    background-size: 100% 100%;
    height: 20px;
    width: 23px;
    margin-right: 15px;
    margin-bottom: 4px;
}

.payment_links_icon {
    background-image: url(../../assets/navbar/payment-link-navbar-icon.svg);
}

.sidebar-text-selected .payment_links_icon {
    background-image: url(../../assets/navbar/payment-link-hover-navbar-icon.svg);
}

.invoice_icon {
    background-image: url(../../assets/navbar/invoice-navbar-icon.svg);
}

.sidebar-text-selected .invoice_icon {
    background-image: url(../../assets/navbar/invoice-hover-navbar-icon.svg);
}

.topbar-search-input:focus-visible {
    outline: none;
}

.seperator {
    height: 40px;
    width: 1.8px;
    background-color: darkgray;
}
