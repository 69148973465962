.support-container {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    padding-left: 150px;
    padding-right: 150px;
    padding-top: 90px;
    padding-bottom: 90px;
    background-color: white;
    border-radius: 33px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
}
